.navbar {
  padding: 10px 20px;
  background-color: transparent; /* Fully transparent background */
  box-shadow: none; /* Remove any box shadow if present */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; /* Ensures navbar stays on top of other elements */
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #343a40; /* Dark text color for the brand */
}

.navbar-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0)!important; /* Fully transparent background */
 
}

.nav-link {
  color: #343a40 !important; /* Dark text color for links */
  font-size: 1.1rem;
}

.nav-link:hover {
  color: #ffecb3 !important; /* A soft hover color */
}

.navbar-text {
  color: #343a40 !important; /* Dark color for text */
  margin-left: auto;
  padding-left: 20px;
  font-size: 0.9rem;
}
