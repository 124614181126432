table {
    width: 100%;
    border-collapse: collapse;
  }
  table, th, td {
    border: 1px solid black;
  }
  th, td {
    padding: 10px;
    text-align: left;
  }
  .button {
    display: inline-block;
    padding: 8px 12px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }
  .button:hover {background-color: #3e8e41}
  .button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  .description-section {
    margin: 20px 0;
  }
  .description {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  .rating {
    display: flex;
    flex-direction: column;
  }
  .rating button {
    margin: 5px 0;
    padding: 10px;
    cursor: pointer;
  }
  .description-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
  .description-form input, .description-form textarea {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
  }
  .description-form button {
    padding: 10px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .container {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px; /* Optional, to control the maximum width */
    margin: 0 auto; /* Centers the container */
  }