.App {
  text-align: center;
}

/* Animation for changing the colors */
@keyframes colorChange {
  0% {
    background-color: rgba(255, 0, 0, 0.8); /* Red */
  }
  25% {
    background-color: rgba(0, 255, 0, 0.8); /* Green */
  }
  50% {
    background-color: rgba(0, 0, 255, 0.8); /* Blue */
  }
  75% {
    background-color: rgba(255, 255, 0, 0.8); /* Yellow */
  }
  100% {
    background-color: rgba(255, 0, 0, 0.8); /* Back to Red */
  }
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css or your main CSS file */

/* Set the background image for the entire app */
body {
  margin: 0;
  
  font-family: 'Arial', sans-serif;
}

.app-background {
  /* Add the background image */
  background-image: url('/src/components/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  /* Add opacity to the background using RGBA or use a pseudo-element */
  position: relative;
  z-index: 1;

  /* Make sure content is centered */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* Pseudo-element for applying an overlay effect */
.app-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0.5, 0.8, 0.2, 0.9));
  background: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.8), /* Dark left side */
  rgba(0.1, 0.1, 0.1, 0.6), /* Gradually lighter in the middle */
  rgba(0.5, 0.8, 0.2, 0.9) /* Lighter green on the right */
);

  z-index: -1; /* Ensures the overlay is behind content */
}
body {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8), /* Dark left side */
    rgba(0.1, 0.1, 0.1, 0.6), /* Gradually lighter in the middle */
    rgba(0.5, 0.8, 0.2, 0.9) /* Lighter green on the right */
  );
  height: 100vh;
  margin: 0;
  padding: 0;
}



.container {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* White background with some transparency */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


h1, h3 {
  color: #fff;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

}


.table-fixed thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}


.table-stripes {
  position: relative;
}

.stripe {
  width: 50px;
  
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: -20px;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.stripe:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Change color on hover */
}

.stripe-link {
  color: white;
  font-size: 120px;
  writing-mode: vertical-rl; /* Rotate text vertically */
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.stripe:hover .stripe-link {
  opacity: 1;
}


/* Applying animation to stripes with index > 10 */
.stripe-animated {
  animation: colorChange 5s infinite;
}

/* BuchstabenSalat should remain in the background */
.salat-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  pointer-events: none;
}

/* Ensure any other content has no background color */
.table {
  background-color: transparent; /* For your table component */
  z-index: 50;
}

/* Extra: Ensure no accidental white box in BuchstabenSalat */
.salat-letter {
  background-color: transparent; /* Make sure letters have no background */
  color: rgba(255, 255, 255, 0.5);
  font-size: 2rem;
  position: absolute;
  transition: transform 0.3s ease, color 0.3s ease;
  pointer-events: auto; /* Only interactable outside the table area */
}



