.salat-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -2; /* Make sure it's in the background */
    pointer-events: none;
  }
  
  .salat-letter {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    pointer-events: auto; /* Allow hover interaction */
    transition: transform 0.3s ease, color 0.3s ease;
    opacity: 0.20;
  }
  
  .salat-letter:hover {
    color: rgba(255, 255, 255, 0);
    transform: scale(1.5);
    opacity: 0.90;
  }
  
  .salat-link {
    display: inline-block;
    color: rgba(0, 153, 255, 0.8);
    text-decoration: none;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    pointer-events: auto;
  }
  
  .salat-letter:hover .salat-link {
    opacity: 1;
    transform: scale(1.2);
  }
  